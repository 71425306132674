import React, { useMemo, useState } from "react";
import { Tree } from "antd";
import { DataNode } from "antd/lib/tree";
import { CheckInfo } from "rc-tree/lib/Tree";
import { UnorderedListOutlined } from "@ant-design/icons";
import { Modal } from "antd";

interface Technique {
  kanji?: string;
  hiragana: string;
  translit: string;
  description: string;
}

interface TechniqueTreeSelectorProps {
  techniques: Record<string, Technique[]>;
  onTechniquesSelected: (selectedTechniques: Technique[]) => void;
}

const TechniqueTreeSelector: React.FC<TechniqueTreeSelectorProps> = ({
  techniques,
  onTechniquesSelected,
}) => {
  const transformTechniquesToTreeData = (
    techniques: Record<string, Technique[]>
  ): DataNode[] => {
    return [
      {
        title: "All",
        key: "all",
        children: Object.keys(techniques).map((category) => ({
          title: category.charAt(0).toUpperCase() + category.slice(1),
          key: `category-${category}`,
          children: techniques[category].map((tech, index) => ({
            title: tech.translit,
            key: `technique-${category}-${index}`,
            isLeaf: true,
          })),
        })),
      },
    ];
  };

  const techniqueMap = useMemo(() => {
    const map = new Map<string, Technique>();
    Object.keys(techniques).forEach((category) => {
      techniques[category].forEach((technique) => {
        map.set(technique.translit, technique);
      });
    });
    return map;
  }, [techniques]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCount, setSelectedCount] = useState<number>(
    Object.values(techniques).flat().length
  );

  const treeData = transformTechniquesToTreeData(techniques);

  const onCheck = (
    checked: React.Key[] | { checked: React.Key[]; halfChecked: React.Key[] },
    info: CheckInfo<DataNode>
  ) => {
    const selectedTechniques = info.checkedNodes;
    const newSelectedTechniques = selectedTechniques
      .filter((node) => node.isLeaf)
      .map((node) => techniqueMap.get(node.title as string)!);
    setSelectedCount(newSelectedTechniques.length);

    onTechniquesSelected(newSelectedTechniques);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  return (
    <div>
      <UnorderedListOutlined
        onClick={showModal}
        style={{ fontSize: "24px", cursor: "pointer" }}
      />
      <span style={{ marginLeft: "10px" }}>
        {selectedCount} / {Object.values(techniques).flat().length}
      </span>
      <Modal
        title="Select Techniques"
        open={isModalVisible}
        onOk={handleOk}
        width={600}
        cancelButtonProps={{ style: { display: "none" } }}
        closable={false}
      >
        <Tree
          checkable
          multiple
          onCheck={onCheck}
          defaultCheckedKeys={["all"]}
          treeData={treeData}
        />
      </Modal>
    </div>
  );
};

export default TechniqueTreeSelector;
