import React, { useState, useRef } from "react";
import {
  Button,
  Typography,
  Layout,
  InputNumber,
  Space,
  Divider,
  Select,
  Switch,
} from "antd";
import {
  AudioOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  RightCircleOutlined,
  RightSquareOutlined,
  SwapRightOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";
import techniques from "./techniques";
import "./App.css";
import WhistleDetector from "./WhistleDetector.tsx";
import TechniqueTreeSelector from "./TechniqueTree.tsx";

const { Option } = Select;
const { Header, Content } = Layout;

interface Technique {
  kanji?: string;
  hiragana: string;
  translit: string;
  description: string;
}

function App() {
  const [intervalSeconds, setIntervalSeconds] = useState(3);
  const [isPlaying, setIsPlaying] = useState(false);

  const [currentTechnique, setCurrentTechnique] = useState<Technique>(); // Changed to object to store full technique data
  const intervalRef = useRef<NodeJS.Timeout>();

  const allTechniques = Object.values(techniques).flat();
  const [currentTechniques, setCurrentTechniques] =
    useState<Technique[]>(allTechniques);

  console.log(currentTechniques);

  const speakPhrase = (phrase, lang) => {
    if ("speechSynthesis" in window) {
      const utterance = new SpeechSynthesisUtterance(phrase);
      utterance.lang = lang;
      window.speechSynthesis.speak(utterance);
    } else {
      alert("Speech synthesis not supported in this browser.");
    }
  };

  const speakRandomTechnique = () => {
    const randomTechnique =
      currentTechniques[Math.floor(Math.random() * currentTechniques.length)];
    setCurrentTechnique(randomTechnique); // Store the full technique data
    const phrase = randomTechnique.hiragana;
    const lang = "ja-JP";
    speakPhrase(phrase, lang);
  };

  const handlePlay = () => {
    if (!isPlaying) {
      speakRandomTechnique();
      if (intervalRef) {
        intervalRef.current = setInterval(
          speakRandomTechnique,
          intervalSeconds * 1000
        );
      }
      setIsPlaying(true);
    }
  };

  const handleStop = () => {
    if (isPlaying) {
      clearInterval(intervalRef.current);
      setIsPlaying(false);
    }
  };

  const techniquesMenu = (
    <TechniqueTreeSelector
      techniques={techniques}
      onTechniquesSelected={setCurrentTechniques}
    />
  );
  const [isWhistleDetectionOn, setIsWhistleDetectionOn] = useState(false);

  return (
    <Layout className="Layout">
      {isWhistleDetectionOn && (
        <WhistleDetector
          sensitivity={200}
          minDuration={300}
          onWhistleDetected={speakRandomTechnique}
        />
      )}
      <Header style={{ height: "80px", lineHeight: "60px" }}>
        <Typography.Title
          level={4}
          style={{ color: "white", textAlign: "center" }}
        >
          Bujinkan Technique Practice
        </Typography.Title>
      </Header>
      <Content className="site-layout-content">
        <Space direction="vertical" size="middle">
          <Space align="center">
            <div style={{ display: "inline-block", color: "white" }}>
              {techniquesMenu}
            </div>

            <Button
              icon={
                isPlaying ? <PauseCircleOutlined /> : <PlayCircleOutlined />
              }
              onClick={() => (isPlaying ? handleStop() : handlePlay())}
              size="small"
            />
            <InputNumber
              min={1}
              max={60}
              defaultValue={intervalSeconds}
              size="small"
              onChange={(v) => v && setIntervalSeconds(v)}
              style={{ width: 60, marginRight: 8 }}
              suffix={"s"}
            />
            <Switch
              checkedChildren="Whistle ON"
              unCheckedChildren="Whistle OFF"
              checked={isWhistleDetectionOn}
              onChange={setIsWhistleDetectionOn}
            />
          </Space>
          <Divider />
          <Typography.Paragraph
            style={{ fontSize: "2rem", color: "#FF4500", marginTop: "1rem" }}
          >
            {currentTechnique?.kanji}
          </Typography.Paragraph>
          <Typography.Text
            style={{ marginTop: "2rem", fontSize: "3rem", color: "#FFFFFF" }}
          >
            {currentTechnique?.translit}
          </Typography.Text>
          <Typography.Paragraph style={{ fontSize: "1.5rem", color: "#999" }}>
            {currentTechnique?.description}
          </Typography.Paragraph>
        </Space>
        <div className="floating-action-button">
          <Button
            type="primary"
            icon={<DoubleRightOutlined />}
            size="large"
            onClick={speakRandomTechnique}
          />
        </div>
      </Content>
    </Layout>
  );
}

export default App;
