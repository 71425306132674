
// techniques.js
const kaitens = [
    // Sempo / Mae Kaiten variations
    { kanji: "前方回転", hiragana: "ぜんぽうかいてん", translit: "Zempo Kaiten", description: "Forward Roll" },
    { kanji: "前方回転両手", hiragana: "ぜんぽうかいてんりょうて", translit: "Zempo Kaiten Ryote", description: "Forward Roll with Both Hands" },
    { kanji: "前方回転片手", hiragana: "ぜんぽうかいてんかたて", translit: "Zempo Kaiten Katate", description: "Forward Roll with One Hand" },
    { kanji: "前方回転無手", hiragana: "ぜんぽうかいてんむて", translit: "Zempo Kaiten Mute", description: "Forward Roll with No Hands" },
    { kanji: "前回転", hiragana: "まえかいてん", translit: "Mae Kaiten", description: "Front Roll" },
    { kanji: "前回転両手", hiragana: "まえかいてんりょうて", translit: "Mae Kaiten Ryote", description: "Front Roll with Both Hands" },
    { kanji: "前回転片手", hiragana: "まえかいてんかたて", translit: "Mae Kaiten Katate", description: "Front Roll with One Hand" },
    { kanji: "前回転無手", hiragana: "まえかいてんむて", translit: "Mae Kaiten Mute", description: "Front Roll with No Hands" },
    // Koho / Ushiro Kaiten variations
    { kanji: "後方転", hiragana: "こうほうてん", translit: "Kouhou Kaiten", description: "Backward Roll" },
    { kanji: "後方転両手", hiragana: "こうほうてんりょうて", translit: "Kouhou Kaiten Ryote", description: "Backward Roll with Both Hands" },
    { kanji: "後方転片手", hiragana: "こうほうてんかたて", translit: "Kouhou Kaiten Katate", description: "Backward Roll with One Hand" },
    { kanji: "後方転無手", hiragana: "こうほうてんむて", translit: "Kouhou Kaiten Mute", description: "Backward Roll with No Hands" },
    { kanji: "後転", hiragana: "うしろてん", translit: "Ushiro Kaiten", description: "Rear Roll" },
    { kanji: "後転両手", hiragana: "うしろてんりょうて", translit: "Ushiro Kaiten Ryote", description: "Rear Roll with Both Hands" },
    { kanji: "後転片手", hiragana: "うしろてんかたて", translit: "Ushiro Kaiten Katate", description: "Rear Roll with One Hand" },
    { kanji: "後転無手", hiragana: "うしろてんむて", translit: "Ushiro Kaiten Mute", description: "Rear Roll with No Hands" },
    // Shokoho / Djoko Kaiten variations
    { kanji: "初期方向転", hiragana: "しょきほうてん", translit: "Shokoho Kaiten", description: "Initial Direction Roll" },
    { kanji: "初期方向転両手", hiragana: "しょきほうてんりょうて", translit: "Shokoho Kaiten Ryote", description: "Initial Direction Roll with Both Hands" },
    { kanji: "初期方向転片手", hiragana: "しょきほうてんかたて", translit: "Shokoho Kaiten Katate", description: "Initial Direction Roll with One Hand" },
    { kanji: "場所転", hiragana: "ばしょてん", translit: "Yoko Kaiten", description: "Side Roll" },
    { kanji: "場所転両手", hiragana: "ばしょてんりょうて", translit: "Yoko Kaiten Ryote", description: "Side Roll with Both Hands" },
    { kanji: "場所転片手", hiragana: "ばしょてんかたて", translit: "Yoko Kaiten Katate", description: "Side Roll with One Hand" },
    // Oten Kaiten variation
    { kanji: "大転転", hiragana: "おおてんてん", translit: "Oo ten Kaiten", description: "Big Rolling Roll with Both Hands" },
    { kanji: "大転転両手", hiragana: "おおてんてんりょうて", translit: "Oo ten Kaiten Ryote", description: "Big Rolling Roll" }
  ];
  
  const ukemis = [
    // Sempo / Mae Ukemi variations
    { kanji: "前方受身", hiragana: "ぜんぽううけみ", translit: "Zempo Ukemi", description: "Forward Breakfall" },
    { kanji: "前方受身両手", hiragana: "ぜんぽううけみりょうて", translit: "Zempo Ukemi Ryote", description: "Forward Breakfall with Both Hands" },
    { kanji: "前方受身片手", hiragana: "ぜんぽううけみかたて", translit: "Zempo Ukemi Katate", description: "Forward Breakfall with One Hand" },
    { kanji: "前受身", hiragana: "まえうけみ", translit: "Mae Ukemi", description: "Front Breakfall" },
    { kanji: "前受身両手", hiragana: "まえうけみりょうて", translit: "Mae Ukemi Ryote", description: "Front Breakfall with Both Hands" },
    { kanji: "前受身片手", hiragana: "まえうけみかたて", translit: "Mae Ukemi Katate", description: "Front Breakfall with One Hand" },
  
    // Koho / Ushiro Ukemi variations
    { kanji: "後方受身", hiragana: "こうほううけみ", translit: "Kouhou Ukemi", description: "Backward Breakfall" },
    { kanji: "後受身", hiragana: "うしろうけみ", translit: "Ushiro Ukemi", description: "Rear Breakfall" },
  
    // Shokoho / Djoko Ukemi variations
    { kanji: "初期方向受身", hiragana: "しょきほうけみ", translit: "Shokohou Ukemi", description: "Initial Direction Breakfall" },
    { kanji: "場所受身", hiragana: "ばしょうけみ", translit: "Yoko Ukemi", description: "Side Breakfall" },
  
    // Shokoho / Djoko Nagare variations
    { kanji: "初期方向流", hiragana: "しょきほうながれ", translit: "Shokoho Nagare", description: "Initial Direction Flow" },
    { kanji: "場所流", hiragana: "ばしょながれ", translit: "Yoko Nagare", description: "Side Flow" },
  ];
  
  const shihouTenchiTobi = [
    // Displacement in 8 directions
    { kanji: "前飛", hiragana: "まえとび", translit: "Mae Tobi", description: "forward jump" },
    { kanji: "後ろ飛", hiragana: "うしろとび", translit: "Ushiro Tobi", description: "backward jump" },
    { kanji: "右飛", hiragana: "みぎとび", translit: "Migi Tobi", description: "right jump" },
    { kanji: "左飛", hiragana: "ひだりとび", translit: "Hidari Tobi", description: "left jump" },
    { kanji: "右前飛", hiragana: "みぎまえとび", translit: "Migi Mae Tobi", description: "forward right jump" },
    { kanji: "左前飛", hiragana: "ひだりまえとび", translit: "Hidari Mae Tobi", description: "forward left jump" },
    { kanji: "右後ろ飛", hiragana: "みぎうしろとび", translit: "Migi Ushiro Tobi", description: "backward right jump" },
    { kanji: "左後ろ飛", hiragana: "ひだりうしろとび", translit: "Hidari Ushiro Tobi", description: "backward left jump" },
  
    // Ten Tobi (heavenly jump)
    { kanji: "天飛", hiragana: "てんとび", translit: "Ten Tobi", description: "heavenly jump" },
  ];
  
  const kamae = [
    // Stances (Kamae)
    { kanji: "正座", hiragana: "せいざ", translit: "Seiza", description: "Formal Kneeling Position" },
    { kanji: "戦闘正座", hiragana: "せんとうせいざ", translit: "Sentou Seiza", description: "Combat Kneeling Position" },
    { kanji: "自然の構え", hiragana: "しぜんのかまえ", translit: "Shizen no Kamae", description: "Natural Stance" },
    { kanji: "平の構え", hiragana: "ひらのかまえ", translit: "Hira no Kamae", description: "Flat Stance" },
    { kanji: "一文字の構え", hiragana: "いちもんじのかまえ", translit: "Ichimonji no Kamae", description: "Straight Line Stance" },
    { kanji: "一文字の構え 両手", hiragana: "いちもんじのかまえ りょうて", translit: "Ichimonji no Kamae Ryote", description: "Straight Line Stance with Both Hands" },
    { kanji: "一文字の構え 片手", hiragana: "いちもんじのかまえ かたて", translit: "Ichimonji no Kamae Katate", description: "Straight Line Stance with One Hand" },
    { kanji: "十文字の構え", hiragana: "じゅうもんじのかまえ", translit: "Juumonji no Kamae", description: "Cross-shaped Stance" },
    { kanji: "平一文字の構え", hiragana: "ひらいちもんじのかまえ", translit: "Hiraichimonji no Kamae", description: "Flat Straight Line Stance" },
    { kanji: "飛鳥の構え", hiragana: "ひちょうのかまえ", translit: "Hichou no Kamae", description: "Flying Bird Stance" },
    { kanji: "土虎の構え", hiragana: "どこのかまえ", translit: "Doko no Kamae", description: "Crouching Tiger Stance" },
  ];
  
const ken = [
    { kanji: "受け流し", hiragana: "うけながし", translit: "Uke Nagashi", description: "Deflecting Block" },
    { kanji: "上段受け流し", hiragana: "じょうだんうけながし", translit: "Jyoudan Uke Nagashi", description: "Upper Tier Deflecting Block" },
    { kanji: "中段受け流し", hiragana: "ちゅうだんうけながし", translit: "Chuudan Uke Nagashi", description: "Middle Tier Deflecting Block" },
    { kanji: "下段受け流し", hiragana: "げだんうけながし", translit: "Gedan Uke Nagashi", description: "Lower Tier Deflecting Block" },
    { kanji: "不動拳", hiragana: "ふどうけん", translit: "Fudou Ken", description: "Immovable Fist" },
    { kanji: "裏手刀", hiragana: "うらしゅとう", translit: "Ura Shutou", description: "Back Hand Sword" },
    { kanji: "表手刀", hiragana: "おもてしゅとう", translit: "Omote Shutou", description: "Front Hand Sword" },
    { kanji: "帽子拳", hiragana: "ぼうしけん", translit: "Boushi Ken", description: "Thumb Nail Strike" },
    { kanji: "赤猿拳", hiragana: "しゃこうけん", translit: "Shakou Ken", description: "Red Ape Fist" },
    { kanji: "指貫拳", hiragana: "しかんけん", translit: "Shikan Ken", description: "Finger Knuckle Fist" },
    { kanji: "真短拳", hiragana: "しんたんけん", translit: "Shin Tan Ken", description: "True Short Fist" },
    { kanji: "三心短拳", hiragana: "さんしんたんけん", translit: "Sanshin Tanken", description: "Three Hearts Fist" },
  ];

  const keri = [
    { kanji: "前蹴り", hiragana: "まえげり", translit: "Mae Geri", description: "Front Kick" },
    { kanji: "前方蹴り", hiragana: "ぜんぽうげり", translit: "Zenpo Geri", description: "Forward Kick" },
    { kanji: "後ろ蹴り", hiragana: "うしろげり", translit: "Ushiro Geri", description: "Backward Kick" },
    { kanji: "後方蹴り", hiragana: "こうほうげり", translit: "Kouhou Geri", description: "Rear Kick" },
    { kanji: "横蹴り", hiragana: "よこげり", translit: "Yoko Geri", description: "Side Kick" },
    { kanji: "小方蹴り", hiragana: "しょうこうほうげり", translit: "Shoukohou Geri", description: "Small Direction Kick" },
    { kanji: "掌蹠蹴り", hiragana: "しょくたくげり", translit: "Shoku Yaku Geri", description: "Flat of the Foot Kick" },
    { kanji: "掌蹠逆蹴り", hiragana: "しょくたくぎゃくげり", translit: "Shoku Gyaku Geri", description: "Ball of the Foot Kick" },
  ];
    

const goKyuuNoKata = [
  { jp: "地の形", hiragana: "ちのかた", translit: "Chi no Kata", description: "Earth Form" },
  { jp: "水の形", hiragana: "みずのかた", translit: "Sui no Kata", description: "Water Form" },
  { jp: "火の形", hiragana: "ひのかた", translit: "Ka no Kata", description: "Fire Form" },
  { jp: "風の形", hiragana: "かぜのかた", translit: "Fu no Kata", description: "Wind Form" },
  // Add more if there are other katas in "Go kyuu no kata"
];
const kihonHappo = [
    { kanji: "一文字の形", hiragana: "いちもんじのかた", translit: "Ichimonji no Kata", description: "Ichimonji Form" },
    { kanji: "十文字の形", hiragana: "じゅうもんじのかた", translit: "Juumonji no Kata", description: "Juumonji Form" },
    { kanji: "飛鳥の形", hiragana: "ひちょうのかた", translit: "Hichou no Kata", description: "Flying Bird Form" },
    { kanji: "表逆", hiragana: "おもてぎゃく", translit: "Omote Gyaku", description: "Outer Reversal" },
    { kanji: "裏逆", hiragana: "うらぎゃく", translit: "Ura Gyaku", description: "Inner Reversal" },
    { kanji: "表逆突き", hiragana: "おもてぎゃくつき", translit: "Omote Gyaku Tsuki / Onikudaki", description: "Front Reversal Thrust" },
    { kanji: "無差取り", hiragana: "むさどり", translit: "Musadori", description: "No Difference Grab" },
    { kanji: "岩石投げ", hiragana: "がんせきなげ", translit: "Ganseki Nage", description: "Rock Throw" },
  ];
  
  const nage = [
    { kanji: "大外投げ", hiragana: "おおそとなげ", translit: "Osoto Nage", description: "Major Outer Throw" },
    { kanji: "大腰投げ", hiragana: "おおごしなげ", translit: "Ogoshi Nage", description: "Major Hip Throw" },
    { kanji: "首投げ", hiragana: "くびなげ", translit: "Kubi Nage", description: "Neck Throw" },
    { kanji: "一本背負い投げ", hiragana: "いっぽんせおいなげ", translit: "Ippon Seoi Nage", description: "One Arm Shoulder Throw" },
    // { kanji: "軽腰投げ", hiragana: "かろいごしなげ", translit: "Karoi Goshi Nage", description: "Light Hip Throw" },
  ];
  const tehodoki = [
    { kanji: "両手解き", hiragana: "りょうてほどき", translit: "Ryote Tehodoki", description: "Both Hands Release" },
    { kanji: "片手解き", hiragana: "かたてほどき", translit: "Katate Tehodoki", description: "One Hand Release" },
    { kanji: "両手解き", hiragana: "もろてほどき", translit: "Morote Tehodoki", description: "Both Hands on One Hand Release" },
    // { kanji: "体解き", hiragana: "たいほどき", translit: "Taihodoki", description: "Whole Body Release" },
  ];
  
  const gyaku = [
    { kanji: "無差取り", hiragana: "むさどり", translit: "Musodori", description: "No Difference Grab" },
    { kanji: "表逆", hiragana: "おもてぎゃく", translit: "Omote Gyaku", description: "Outer Reversal" },
    { kanji: "逆投げ", hiragana: "ぎゃくなげ", translit: "Gyaku Nage", description: "Reversal Throw" },
    { kanji: "本逆", hiragana: "ほんぎゃく", translit: "Hon Gyaku", description: "Basic Reversal" },
    { kanji: "本締め", hiragana: "ほんじめ", translit: "Hon Jime", description: "Basic Strangle" },
  ];
  

const techniques = {
  kaitens,
  ukemis,
  shihouTenchiTobi,
  kamae,
  ken,
  keri,
  goKyuuNoKata,
  kihonHappo,
  nage,
  tehodoki,
  gyaku,
};

export default techniques;
