import React, { useEffect } from "react";

interface WhistleDetectorProps {
  sensitivity: number;
  minDuration: number;
  onWhistleDetected: () => void;
}

const WhistleDetector: React.FC<WhistleDetectorProps> = ({
  sensitivity,
  minDuration,
  onWhistleDetected,
}) => {
  useEffect(() => {
    let whistleStartTime: number | null = null;
    let isWhistleDetected = false;
    let audioContext: AudioContext | null = null;
    let isEffectActive = true; // Flag to manage effect lifecycle

    const detectWhistle = (
      analyser: AnalyserNode,
      dataArray: Uint8Array,
      bufferLength: number,
      audioCtx: AudioContext
    ) => {
      analyser.getByteFrequencyData(dataArray);

      let maxVolume = 0;
      let dominantFrequency = 0;

      for (let i = 0; i < bufferLength; i++) {
        if (dataArray[i] > maxVolume) {
          maxVolume = dataArray[i];
          dominantFrequency = (i * audioCtx.sampleRate) / analyser.fftSize;
        }
      }

      if (
        maxVolume > sensitivity &&
        dominantFrequency > 1000 &&
        dominantFrequency < 4000
      ) {
        if (!isWhistleDetected) {
          whistleStartTime = Date.now();
          isWhistleDetected = true;
        } else if (
          whistleStartTime &&
          Date.now() - whistleStartTime > minDuration &&
          isEffectActive
        ) {
          onWhistleDetected();
          isWhistleDetected = false;
        }
      } else {
        isWhistleDetected = false;
      }

      requestAnimationFrame(() =>
        detectWhistle(analyser, dataArray, bufferLength, audioCtx)
      );
    };

    let stream: MediaStream | null = null;
    let microphone: MediaStreamAudioSourceNode | null = null;
    let analyser: AnalyserNode | null = null;

    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((mediaStream) => {
        stream = mediaStream;
        audioContext = new AudioContext();
        analyser = audioContext.createAnalyser();
        microphone = audioContext.createMediaStreamSource(mediaStream);
        microphone.connect(analyser);
        analyser.fftSize = 2048;

        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        detectWhistle(analyser, dataArray, bufferLength, audioContext);
      })
      .catch((err) => console.error("Could not access microphone", err));

    return () => {
      if (audioContext) {
        audioContext.close();
        console.log("Closed audio context");
        audioContext = null;
      }
      if (microphone) {
        microphone.disconnect();
        console.log("Disconnected microphone");
        microphone = null;
      }
      if (analyser) {
        analyser.disconnect();
        console.log("Disconnected analyser");
        analyser = null;
      }
      isEffectActive = false; // Set the flag to false on cleanup
      // Stop all tracks on the stream
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
        console.log("Stopped all tracks");
      }
      stream = null;
    };
  }, [sensitivity, minDuration, onWhistleDetected]);

  return <></>;
};

export default WhistleDetector;
